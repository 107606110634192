/* RESPONSIBLE TEAM: team-actions */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

export const NON_USER_ROLE_AUDIENCE = ['lead', 'visitor', 'non_user'];

// This functionality is shared between the models workflow-connector/action and workflow-connector/insertable-action
export class BaseAction extends Model {
  @service declare appService: any;
  @service declare store: Store;

  @belongsTo('matching-system/ruleset', { async: false }) declare ruleset: any;
  @attr('string') declare usage: string;

  nonUserRoleAudience = NON_USER_ROLE_AUDIENCE;

  setDefaultRuleset() {
    if (this.ruleset) {
      return;
    }

    let ruleset = this.store.createRecord('matching-system/ruleset');
    ruleset.set('predicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('clientPredicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('rolePredicateGroup', this.store.createFragment('predicates/predicate-group'));
    ruleset.set('defaultPredicateGroup', this.store.createFragment('predicates/predicate-group'));

    this.ruleset = ruleset;
  }

  get isFinStandaloneAction() {
    return this.usage === 'fin';
  }

  get isMessengerInstalledAndProtected(): boolean {
    return this.userProtectedFromSpoofing && !this.isMessengerDisabledOnAllPlatforms;
  }

  get isMessengerDisabledOnAllPlatforms(): boolean {
    let isWebDisabled = this.appService.app.is_web_messenger_disabled;
    let iosSdkApp = this.appService.app.iosSdkApps.firstObject;
    let androidSdkApp = this.appService.app.androidSdkApps.firstObject;

    let isIosDisabled = !iosSdkApp || iosSdkApp.is_disabled;
    let isAndroidDisabled = !androidSdkApp || androidSdkApp.is_disabled;

    return isWebDisabled && isIosDisabled && isAndroidDisabled;
  }

  get userProtectedFromSpoofing(): boolean {
    if (this.isFinStandaloneAction && this.noUserInAudience()) {
      return true;
    }

    return this.webSecure() && this.iosSecure() && this.androidSecure();
  }

  private noUserInAudience() {
    return this.nonUserRoleAudience.includes(this.ruleset.userType);
  }

  private webSecure() {
    return this.appService.app.is_web_messenger_disabled || this.appService.app.identityVerified;
  }

  private iosSecure() {
    let iosSdkApp = this.appService.app.iosSdkApps.firstObject;
    if (!iosSdkApp) {
      return true;
    }
    return iosSdkApp.is_disabled || iosSdkApp.identityVerified;
  }

  private androidSecure() {
    let androidSdkApp = this.appService.app.androidSdkApps.firstObject;
    if (!androidSdkApp) {
      return true;
    }

    return androidSdkApp.is_disabled || androidSdkApp.identityVerified;
  }
}
